.swiper-slide {
  a {
    text-decoration: none;
  }
}
.product-card {
  padding: 0.75rem;
  position: relative;
  min-height: 175px;

  .ant-progress {
    position: absolute;
    right: 10px;
    z-index: 9;
    top: 5px;
    background-color: #fff;
    border-radius: 50%;
    .ant-progress-text {
      padding-top: 2px;
    }
    @media (max-width: 767px) {
      .ant-progress-inner {
        width: 32px !important;
        height: 32px !important;
        .ant-progress-text {
          font-size: 11px !important;
          line-height: 1.25;
        }
      }
    }
  }

  .product-img {
    max-width: 100%;
    object-fit: contain;
    height: 130px;
    width: 150px;
    margin: 0.5rem auto;
  }
  @media (max-width: 767px) {
    .product-img {
      height: 110px;
      width: 110px;
    }
  }

  .product-title {
    margin: 0.3rem 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #4e4e4e;
    display: -webkit-box;
    line-height: 24px;
    margin-bottom: 0;
    max-height: 48px;
    min-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    font-size: 15px;
    @media (max-width: 767px) {
      font-size: 14px;
      -webkit-line-clamp: 2;
      line-height: 21px;
      max-height: 35px;
      min-height: 35px;
    }
  }
}
